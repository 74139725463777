import BottomSheet from '@components/BottomSheet/index';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { AnimatePresence, motion } from 'framer-motion';
//import navigate from gatsby
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';

function ScrollCTA({ children, ...props }) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [allowed, setAllowed] = useState(false);
  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [y, setY] = useState(0);

  let timeout = null;

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;

      if (y > window.scrollY) {
        setAllowed(true);
        setScrollDirection('up');
      } else if (y < window.scrollY) {
        setAllowed(false);
        setScrollDirection('down');
      }
      setIsScrolling(true);

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 200);
      setY(window.scrollY);
      if (
        window.scrollY > props.startAt &&
        !(
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 500
        )
      ) {
        setAllowed(true);
      } else {
        setAllowed(false);
      }
    },
    [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    setY(window.scrollY);
  }, []);

  const onClose = (e) => {
    let event = props.closeEvent;
    GTM.track(gtmEvents[event], {
      type: props.type,
      location: props.location,
      ...(props.meId && { meId: props.meId }),
      ...(props.secondaryType && { secondaryType: props.secondaryType }),
    });
    setShowBottomSheet(false);
  };

  return (
    <div style={{ fontFamily: 'Manrope' }}>
      <AnimatePresence>
        {showBottomSheet ? (
          <BottomSheet onClose={onClose} {...props}>
            {React.cloneElement(children, {
              ...props,
            })}
          </BottomSheet>
        ) : (!isScrolling || scrollDirection == 'up') &&
          allowed &&
          !state?.userIsApplying ? (
          <div className="flex justify-center">
            <motion.div
              onClick={() => {
                let event = props.openEvent;
                GTM.track(gtmEvents[event], {
                  type: props.type,
                  location: props.location,
                  ...(props.meId && { meId: props.meId }),
                  ...(props.secondaryType && {
                    secondaryType: props.secondaryType,
                  }),
                });
                setShowBottomSheet(true);

                // if (state.email.length == 0) {
                //   setShowBottomSheet(true);
                //   return;
                // }
                // GTM.login({
                //   userId: state.email,
                //   email: state.email,
                // });
                // GTM.track(gtmEvents.EMAIL_SUBMITTED, {
                //   type: props.type,
                //   location: props.location,
                //   ...(props.meId && { meId: props.meId }),
                //   ...(props.secondaryType && {
                //     secondaryType: props.secondaryType,
                //   }),
                // });
                // let hash = btoa(props.type + props.link);
                // if (
                //   state.registrationDetails &&
                //   state.registrationDetails[hash]
                // ) {
                //   navigate(
                //     `/registered/?id=${state.registrationDetails[hash].id}`,
                //   );
                // } else {
                //   navigate('/registration', {
                //     state: {
                //       program: props.program,
                //       link: props.link,
                //       type: props.type,
                //     },
                //   });
                // }
              }}
              className="scroll-cta z-50 w-3/4 text-center md:hidden"
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ type: 'tween', stiffness: 100 }}
              exit={{ y: 100, opacity: 0 }}
            >
              {React.cloneElement(children, {
                ...props,
              })}
            </motion.div>
          </div>
        ) : (
          ''
        )}{' '}
      </AnimatePresence>
      <style>
        {`
          .scroll-cta{
             width:100%;
             position:fixed;
             bottom:20px;
             z-index:50;
             display:flex;
             justify-content:center;
          }
          `}
      </style>
    </div>
  );
}

export default ScrollCTA;
