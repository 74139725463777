// extracted by mini-css-extract-plugin
export var accordion = "TrialDeveloperExperience-module--accordion--QdXmV";
export var banner = "TrialDeveloperExperience-module--banner--8rddJ";
export var bannerMobile = "TrialDeveloperExperience-module--bannerMobile--ZHsGM";
export var build = "TrialDeveloperExperience-module--build--EisE3";
export var button = "TrialDeveloperExperience-module--button--GOaUy";
export var card = "TrialDeveloperExperience-module--card--Py388";
export var circle = "TrialDeveloperExperience-module--circle--xI1Vd";
export var custom = "TrialDeveloperExperience-module--custom--KA35s";
export var customFont = "TrialDeveloperExperience-module--customFont--aUd06";
export var detail = "TrialDeveloperExperience-module--detail--hAmoY";
export var emailForm = "TrialDeveloperExperience-module--emailForm--QaPyv";
export var heading = "TrialDeveloperExperience-module--heading--mgr7Q";
export var imageBadge = "TrialDeveloperExperience-module--imageBadge--ApAu7";
export var inputControl = "TrialDeveloperExperience-module--inputControl--YvHwq";
export var inputControlMobile = "TrialDeveloperExperience-module--inputControlMobile--EqWnf";
export var learn = "TrialDeveloperExperience-module--learn--+TaBh";
export var milestoneCard = "TrialDeveloperExperience-module--milestoneCard--9aTzo";
export var parent = "TrialDeveloperExperience-module--parent--AfUGX";
export var paymentImage = "TrialDeveloperExperience-module--paymentImage--nb2y-";
export var play = "TrialDeveloperExperience-module--play--5BQfx";
export var sketch = "TrialDeveloperExperience-module--sketch--9I95r";
export var sketchParent = "TrialDeveloperExperience-module--sketchParent--H7lXZ";
export var skill = "TrialDeveloperExperience-module--skill--51rL2";
export var testimonialCard = "TrialDeveloperExperience-module--testimonialCard--CJs+W";
export var testimonialOwner = "TrialDeveloperExperience-module--testimonialOwner---RiN6";
export var whyCrioImage = "TrialDeveloperExperience-module--whyCrioImage--s0x7q";