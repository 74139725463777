// FDT/BDT anchorIds coming from strapi anchorId field

const EFellowshipAnchorIds = {
  PROGRAMMING_ESSENTIALS: 'programming-essentials',
  DATA_STRUCTURE: 'data-structures',
  DEVELOPER_ESSENTIALS: 'developer-essentials',
  SYSTEM_DESIGN: 'system-design',
  FULLSTACK: 'full-stack-course',
  BACKEND: 'backend-course',
};

const EBytesAnchorIds = {
  JAVA_CONCEPTS: 'java-concepts',
  DEVELOPER_ESSENTIALS_PROCESS: 'developer-essentials-process',
  OOPS_FOUNDATION: 'oops-foundations',
  DEVELOPER_ESSENTIALS_WEB: 'developer-essentials-web',
};

const EAnchorIds = {
  CAREER_SERVICES: 'career-services',
  TECHNOLOGIES: 'technologies',
  PROGRAMS: 'programs',
  ESSENTIAL_DEVELOPER_SKILLS: 'essential-developer-skills',
};

module.exports = {
  EAnchorIds,
  EBytesAnchorIds,
  EFellowshipAnchorIds,
};
