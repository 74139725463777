import { EProgramIDs } from '@src/constants/data/programs/index';
import { EScholarship } from '@src/constants/sale/index';
import React from 'react';

export const stripData = {
  [EProgramIDs.FREE_TRIAL]: {
    title: (
      <>
        #AddProjectsToResume and get <br className="hidden md:block" /> jobs at
        top tech companies.
      </>
    ),
    highlightText: (
      <>
        {/* Make 2022 your <span className="font-bold">#YearofDoing</span> */}
      </>
    ),
    subtitle: (
      <p className="">
        Get upto Rs. {EScholarship[EProgramIDs.FREE_TRIAL]} scholarship.
        <br />
        <span className="text-sm font-normal md:text-base">
          Limited Time Offer.
        </span>
      </p>
    ),
    program: EProgramIDs.FREE_TRIAL,
  },
  [EProgramIDs.BACKEND_DEVELOPER_EXPERIENCE]: {
    title: (
      <>
        #AddProjectsToResume and get <br /> jobs at top tech companies.
      </>
    ),
    highlightText: (
      <>
        {/* Make 2022 your <span className="font-bold">#YearofDoing</span> */}
      </>
    ),
    subtitle: (
      <p className="mt-2 text-sm text-yearOfDoingSale-neutral-100 md:text-xl">
        Earn a{' '}
        <u className="font-bold">
          Rs. {EScholarship[EProgramIDs.BACKEND_DEVELOPER_EXPERIENCE]}{' '}
          scholarship
        </u>
        .
        <br className="md:hidden" /> Limited Time Offer.
      </p>
    ),
  },
  [EProgramIDs.FULL_STACK]: {
    title: (
      <>
        #AddProjectsToResume and get <br /> jobs at top tech companies.
      </>
    ),
    highlightText: (
      <>
        {/* Make 2022 your <span className="font-bold">#YearofDoing</span> */}
      </>
    ),
    subtitle: (
      <p className="mt-2 text-sm text-yearOfDoingSale-neutral-100 md:text-xl">
        Earn a{' '}
        <u className=" font-bold">
          Rs. {EScholarship[EProgramIDs.FULL_STACK]} scholarship
        </u>
        .
        <br className="md:hidden" /> Limited Time Offer.
      </p>
    ),
  },
  [EProgramIDs.FELLOWSHIP]: {
    title: (
      <>
        #AddProjectsToResume and get <br className="hidden md:block" /> jobs at
        top tech companies.
      </>
    ),
    highlightText: (
      <>
        {/* Make 2022 your <span className="font-bold">#YearofDoing</span> */}
      </>
    ),
    subtitle: (
      <>
        Get a Rs. {EScholarship[EProgramIDs.FELLOWSHIP]} scholarship.
        <br />
        <span className="text-sm font-normal md:text-base">
          Limited Time Offer.
        </span>
      </>
    ),
  },
  [EProgramIDs.QA_AUTOMATION_TESTING]: {
    title: (
      <>
        #AddProjectsToResume and get <br className="hidden md:block" /> jobs at
        top tech companies.
      </>
    ),
    highlightText: (
      <>
        {/* Make 2022 your <span className="font-bold">#YearofDoing</span> */}
      </>
    ),
    subtitle: (
      <>
        Get a Rs.{EScholarship[EProgramIDs.QA_AUTOMATION_TESTING]} scholarship.
        <br />
        <span className="text-sm font-normal md:text-base">
          Limited Time Offer.
        </span>
      </>
    ),
  },
  [EScholarship.MASTERS_PROGRAM]: {
    title: (
      <>
        #AddProjectsToResume and get <br /> jobs at top tech companies.
      </>
    ),
    highlightText: (
      <>
        {/* Make 2022 your <span className="font-bold">#YearofDoing</span> */}
      </>
    ),
    subtitle: (
      <>
        Get a Rs.{EScholarship[EProgramIDs.MASTERS_PROGRAM]} scholarship.
        <br />
        <span className="text-base font-normal">Limited Time Offer.</span>
      </>
    ),
  },
};
